/**
 * Copyright Highway9 Networks Inc.
 */
import LocalizedStrings from 'react-localization';

export const text = new LocalizedStrings({
  en: {
    actions: "Actions",
    VMC_ReleasePanel: "Mobile Cloud Releases",
    EDGE_ReleasePanel: "EDGE Releases",
    CARRIER_GATEWAY_EDGE_ReleasePanel: "CARRIER GATEWAY EDGE Releases",
    Radio_ReleasePanel: "Radio Releases",
    Administration: "Administration",
    serviceInstance: "Mobile Cloud",
    serviceInstanceDisplayName: "Mobile Cloud Display Name",
    serviceInstanceCorePortalLink: "Mobile Cloud Portal Link",
    installationSteps: "Installation Steps",
    virtualizedVMware: "Virtualized - VMware",
    hardwareImportFileError: `Please upload the excel file to proceed.`,
    paidResourceInfo: "Check this box if you have paid asset. Leave it unchecked for radios designated for Proof of Concept (POC) or testing.",
    upgradeWarning: "Upgrading of this Mobile Cloud is locked. If you still want to upgrade then make sure you are upgrading to the correct build and enter the string (IKnowThisIsToBeUpgraded) in the text box below.",
    bareMetal: "Bare Metal",
    vmcUpdate: 'This CSP is upgraded. You need to refresh this page to see the upgraded UI.',
    navBarTitle: "Highway 9 Console ",
    EULAText: "License Agreement",
    logout: "Logout",
    radios: "Radio AP",
    tenants: "Tenants",
    partners: "Partners",
    serviceInstances: "Mobile Clouds",
    orderManagement: 'Order Management',
    userManagement: 'User Management',
    cloudDashboard: 'Cloud Dashboard',
    settings: "Settings",
    edges: "Edges",
    cloudTypes: "Cloud Types",
    locations: "Locations",
    subscribers: "Devices",
    version: "Version",
    new: "New",
    edit: "Edit",
    delete: "Delete",
    id: "ID",
    name: "Name",
    email: "Email",
    creationTime: "Creation Time",
    updationTime: "Updation Time",
    createUpgradeTime: "Create/Upgrade Time",
    status: "Status",
    operationalState: "Operational State",
    statusMessage: "Status Message",
    addTenant: "Add New Tenant",
    addTenantDesc: "This will add new tenant",
    addPartner: "Add New Partner",
    addPartnerDesc: "This will add new partner",
    tenantName: "Tenant Name",
    partnerName: "Partner Name",
    tenantEmail: "Tenant Email",
    partnerEmail: "Partner Email",
    addTenant_btn: "Add Tenant",
    addPartner_btn: "Add Partner",
    editTenant: "Edit Tenant",
    editPartner: "Edit Partner",
    editTenantDesc: "This will edit tenant",
    deleteTenant: "Delete Tenant",
    deleteTenantDesc: "Are you sure you want to delete this tenant?",
    editPartnerDesc: "This will edit Partner",
    deletePartner: "Delete partner",
    deletePartnerDesc: "Are you sure you want to delete this partner?",
    changePassword: "Get Password Reset Link",
    yes: "Yes",
    private4GTitle: "Mobile Cloud",
    private4GTitleName: "{0} Name",
    private4GCloudLocation: "{0} Location",
    private4GCloudLocationType: "{0} location type",
    private4GDesc: "A fully cloud managed {0} that is customized for setting up a network for a enterprise.",
    Add: "Add",
    AddNew: "Add New",
    AddON: "(Add On)",
    edge: "Mobile Edge",
    edgeTitle: "{0} {1}",
    edgeTitleDesc: "Add-on for {0}. Add an additional Edge for a existing site.",
    EdgeRelease: "Edge Release",
    VMCRelease: "Mobile Cloud Release",
    radioTitle: "{0} {1}",
    radioTitleDesc: "Add-on for {0}. Add a additional Radio/access point for a site.",
    device: "Device",
    deviceTitle: "{0} {1}",
    deviceTitleDesc: "Add-on for {0}. Add additional devices to a {0}.",
    controller: "Mobile Cloud",
    controllerDesc: "Deploy a {0} to manage devices and policies for your private cellular network.",
    tenant: "Tenant",
    partner: "Partner",
    noOfInterfaces1: "1-Interface",
    noOfInterfaces2: "2-Interface",
    noOfInterfaces3: "3-Interface",
    google: "Google Cloud",
    amazon: "Amazon Cloud",
    azure: "Azure Cloud",
    cloudLocationDesc: "Chose a cloud location central to your private network deployment.",
    access: "Access Points",
    devices: "Devices",
    edgeDeployDesc: "Deploy an edge to manage the {0}'s control and data plane.",
    location: "Location",
    noOfInterfaces: "Number of Interfaces",
    type: "Type",
    edgeLocationAndType: "{0} {1}",
    concatTwoStrings: "{0} {1}",
    radio: "Radio AP",
    radioOrdertDesc: "Register a radio / access point to allow end-user devices to connect to the mobile network. One or more radios may be needed to provide full coverage for a given site.",
    radioOrder: "Order {0}",
    altitude: "Altitude/Height of Radio (m) above ground level",
    devicesOrderDesc: "Each User Equipment is represented by a device. Each device has a SIM card.",
    subscribersCount: "Device Count",
    addSimulatedDevices: "Add Simulated Devices",
    addSimulatedDevicesTesting: "{0} for testing",
    addSimulatedDevicesTestingdesc: "A few simulated radios and devices will be deployed for tesing.",
    simulatedDevicesPerEdge: "Simulated Devices Per Edge",
    generateSimulatedTraffic: "Generate Simulated Traffic",
    generateSimulatedTrafficDesc: "If enabled, the simulated devices will automatically generate network traffic after deployment.",
    upgrade: "Upgrade",
    upgradedOn: "Upgraded On",
    retry: "Retry",
    downloadSubscribers: "Download Subscribers",
    core: "Core",
    refresh: "Refresh",
    refreshing: "Refreshing",
    orderID: "Order ID",
    orderName: "Order Name",
    orderType: 'Order Type',
    orderDate: "Order Date",
    completedOn: "Completed On",
    addNewEdge: "Add New Edge",
    serviceInstanceName: "Service Instance Name",
    GCP: "Google Cloud",
    AWS: "Amazon Cloud",
    ON_PREMISE: "Private Cloud",
    placeOrder: "Place Order",
    addNewRadio: "Add New Radio",
    addNewDevices: "Add New Devices",
    selectDeploymentLocation: "Select Deployment Location",
    saveChanges: "Save changes",
    notImplemented: "Not Implemented",
    loginTitle: "Highway 9 Networks Console",
    resetTitle: "Reset Password",
    userName: "Username",
    password: "Password",
    confirmPassword: "Confirm Password",
    passwordNotMatch: "Password does not match",
    passwordTooShort: "Password is too short",
    reset: 'Reset',
    login: "Login",
    activeSubscribers: "Active Subscribers",
    upgradeAllServiceInstances: "Upgrade all edges in this service instance",
    upgradeSchedule: "Upgrade Schedule:",
    upgradeNow: "Upgrade Now",
    upgradeAt: "Upgrade at:",
    upgradeOtherReleases: "Upgrade to other release:",
    upgradeToRecommended: "Upgrade to recommended release:",
    addUser: "Add New User",
    editUser: "Edit User",
    editUserDesc: "This will edit user",
    addUserDesc: "This will add new user",
    userEmail: "User Email",
    userPassword: "User Password",
    displayName: "Display Name",
    role: "Role",
    authType: "AuthType",
    auditLog: "Audit Log",
    operationType: "Operation Type",
    objectType: "Object Type",
    object: "Object",
    user: "User",
    userRole: "User Role",
    userIp: "User IP",
    operationResult: "Operation Result",
    deleteUserDesc: "Are you sure you want to delete this user?",
    deleteUser: "Delete User",
    next: "Next",
    profile: "Profile",
    oldPassword: "Old Password",
    newPassword: "New Password",
    repeatPassword: "Repeat Password",
    addUser_btn: "Add User",
    updateUser_btn: "Update User",
    enableEdgeIpsec: "Enable IPSec for edge",
    enableEdgeFastPath: "Enable Fastpath",
    apiDocs: "API Documentation",
    editProfile: "Edit Profile",
    updateProfile_btn: "Update Profile",
    configuration: "Configuration",
    VMCs: "Mobile Clouds",
    VMCDeployment: "Are you sure you want to retry deployment of Mobile Cloud",
    passwordWarning: "Password must be more than 8 characters",
    mcc: "MCC",
    mnc: "MNC",
    tac: "TAC",
    IMSIBlock: "IMSI Block",
    SIMType: "SIM Type",
    active: "Active",
    used: "Used",
    total: "Total",
    addIMSI_btn: "Add IMSI Block",
    updateIMSI_btn: "Update IMSI Block",
    updateSimOrder_btn: "Update Sim Order",
    IMSIStart: "IMSI Start",
    IMSIEnd: "IMSI End",
    deleteMessage: "Are you sure you want to delete",
    downloadTemplate: "Download Template",
    resyncSmdp: "Sync ESIMs",
    cancelOrder: "Cancel Order",
    update: "Update",
    simCount: "SIM Count",
    physicalSIM: "Physical SIM",
    eSIM: "eSIM",
    orderDevices: "Managed - Configured SIM Cards assigned from the SIM inventory",
    orderTenantDevices: "Partially Managed - Highway 9 Networks managed but generated by your own SIM vendor",
    orderCustom: "Unmanaged - Generated SIM Cards pending Configuration",
    simManagementToolTip: <>SIM Management involves:-<br />
      <b>SIM Management</b> - Management of IMSI Ranges, OP, OPC and other values required for SIM Generation<br />
      <b>SIM Generation</b> - Generation of the physical SIM Card or eSIM with QR Code. The IMSI, OP, OPC and other data is written on it so that it can be given to the user to insert in the device.</>,
    psimBlock: "PSIM IMSI Block",
    esimBlock: "ESIM IMSI Block",
    vendor: "Vendor",
    functionCallId: "FunctionCallId",
    tag: "Tag",
    export: "Export",
    import: "Import",
    uploadFile: "Upload File",
    importDesc: "Upload file to import bulk",
    upload: "Upload",
    IMSI: "IMSI",
    PLMN: "PLMN",
    IBN: "IBN",
    ICCID: "ICCID",
    blockMgmtType: "Managed by",
    SimType: "Sim Type",
    State: "State",
    Note: "Note",
    Ki: "Ki",
    OPC: "OPC",
    details: "Details",
    close: "Close",
    locationSyncMode: "Location Sync Mode",
    manualLocation: "Manual",
    gnssLocation: "GNSS",
    networkMode: "Network Mode",
    networkType: "Network Type",
    gateway: "Gateway",
    extension: "Network Extension",
    edgeNetworkModeWarning: "All edges in a Mobile Cloud must have the same Network mode. Currently there are edges in this Mobile Cloud with Network Mode {0}. Deploying this edge with a different network mode may impact the existing edges.",
    gnnsHelpText: "Use Radio location from GPS",
    hardwareManagement: "Hardware Management",
    revoke: "Revoke",
    hardwareType: "Hardware Type",
    hardwareID: "Hardware ID",
    hardware: "Hardware",
    hardwareDescription: "Hardware Description",
    remove: "Remove",
    save: "Save",
    assignHardware: "Assign Hardware",
    assign: "Assign",
    services: "Services",
    simManagement: "SIM Management",
    releases: "Software Releases",
    ECIManagement: "ECI Management",
    modelType: "Model Type",
    invoiceNumber: "Invoice Number",
    assignTo: "Assigned Mobile Cloud",
    h9DeploymentCloud: "Use Highway 9 Deployment Cloud",
    approve: "Approve",
    setDefault: "Set Default",
    removeDefault: "Remove Default",
    deleteDeploymentTitle: "Delete Deployment Cloud",
    deleteDeploymentDesc: "Are you sure you want to delete this Deployment Cloud?",
    setDefaultDeploymentTitle: "Set Default Deployment Cloud",
    setDefaultDeploymentDesc: "Are you sure you want to make it as default Deployment Cloud?",
    removeDefaultDeploymentTitle: "Remove Default Deployment Cloud",
    removeDefaultDeploymentDesc: "Are you sure you want to remove it as default Deployment Cloud?",
    agentName: "Agent Name",
    thumbPrint: "Thumb Print",
    description: "Description",
    deploymentCluster: "Deployment Cloud",
    partnerRadioAgent: "Partner Radio Agent",
    reject: "Reject",
    rejectMessage: "Are you sure you want to reject",
    apiToken: "API Token",
    deployment: "Deployment",
    deviceManagement: "Device Management",
    TenantConfigPaneL:"Tenant Config",
    tenantOfferWarning: "This tenant has cancelled the offer.",
    ready: "READY",
    deleteMobileCloud: "Delete Mobile Cloud",
    forceDeleteResourceLeak: "Force Delete can result in resource leak",
    operationCannotBeUndone: "This operation cannot be undone.",
    deletingMobileCloudWarning: "Deleting a MobileCloud will permanently remove all edges, devices and the MobileCloud configuration. Once deleted it cannot be recovered.",
    confirmMobileCloudDeletion: "Confirm deletion by typing the MobileCloud name below :",
    cancel: "Cancel",
    forceDelete: "Force Delete",
    mobileCloudName: "MobileCloud Name"
  },
  it: {
    radios: "Radio AP...",
  },
  hi: {
    navBarTitle: "हाईवे9 कंसोल - ",
    radios: "रेडियो एपी",
    tenants: "किरायेदारों",
    serviceInstances: "आभासी मोबाइल बादल",
    orderManagement: 'आदेश का प्रबंधन',
    userManagement: 'उपयोगकर्ता प्रबंधन',
    cloudDashboard: 'क्लाउड डैशबोर्ड',
    settings: "समायोजन",
    edges: "किनारों",
    cloudTypes: "बादल प्रकार",
    locations: "स्थानों",
    subscribers: "ग्राहकों",
    new: "नया",
    edit: "संपादित करें",
    delete: "हटाएं",
    id: "ID",
    name: "नाम",
    email: "ईमेल",
    creationTime: "रचना समय",
    status: "स्थिति",
    statusMessage: "स्थिति संदेश",
    addTenant: "नया किरायेदार जोड़ें",
    addTenantDesc: "इससे नया किरायेदार जुड़ जाएगा",
    tenantName: "किरायेदार का नाम",
    tenantEmail: "किरायेदार ईमेल",
    addTenant_btn: "किरायेदार जोड़ें",
    editTenant: "किरायेदार संपादित करें",
    editTenantDesc: "यह किरायेदार को संपादित करेगा",
    deleteTenant: "किरायेदार हटाएं",
    deleteTenantDesc: "क्या आप वाकई इस टैनेंट को हटाना चाहते हैं?",
    yes: "हां",
  }
});