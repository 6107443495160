import { P as i, _ as O } from "./styled-753b5b29.js";
import { _ as S } from "./inheritsLoose-0ff04196.js";
import x from "react";
import T from "react-dom";
function C(u, s) {
  return process.env.NODE_ENV === "production" ? () => null : function(...r) {
    return u(...r) || s(...r);
  };
}
function R(u) {
  const {
    prototype: s = {}
  } = u;
  return !!s.isReactComponent;
}
function k(u, s, a, r, e) {
  const t = u[s], n = e || s;
  if (t == null || // When server-side rendering React doesn't warn either.
  // This is not an accurate check for SSR.
  // This is only in place for emotion compat.
  // TODO: Revisit once https://github.com/facebook/react/issues/20047 is resolved.
  typeof window > "u")
    return null;
  let o;
  return typeof t == "function" && !R(t) && (o = "Did you accidentally provide a plain function component instead?"), o !== void 0 ? new Error(`Invalid ${r} \`${n}\` supplied to \`${a}\`. Expected an element type that can hold a ref. ${o} For more information see https://mui.com/r/caveat-with-refs-guide`) : null;
}
const U = C(i.elementType, k), N = {
  disabled: !1
};
var _ = process.env.NODE_ENV !== "production" ? i.oneOfType([i.number, i.shape({
  enter: i.number,
  exit: i.number,
  appear: i.number
}).isRequired]) : null;
process.env.NODE_ENV !== "production" && i.oneOfType([i.string, i.shape({
  enter: i.string,
  exit: i.string,
  active: i.string
}), i.shape({
  enter: i.string,
  enterDone: i.string,
  enterActive: i.string,
  exit: i.string,
  exitDone: i.string,
  exitActive: i.string
})]);
const y = x.createContext(null);
var w = function(s) {
  return s.scrollTop;
}, v = "unmounted", f = "exited", c = "entering", h = "entered", g = "exiting", l = /* @__PURE__ */ function(u) {
  S(s, u);
  function s(r, e) {
    var t;
    t = u.call(this, r, e) || this;
    var n = e, o = n && !n.isMounting ? r.enter : r.appear, p;
    return t.appearStatus = null, r.in ? o ? (p = f, t.appearStatus = c) : p = h : r.unmountOnExit || r.mountOnEnter ? p = v : p = f, t.state = {
      status: p
    }, t.nextCallback = null, t;
  }
  s.getDerivedStateFromProps = function(e, t) {
    var n = e.in;
    return n && t.status === v ? {
      status: f
    } : null;
  };
  var a = s.prototype;
  return a.componentDidMount = function() {
    this.updateStatus(!0, this.appearStatus);
  }, a.componentDidUpdate = function(e) {
    var t = null;
    if (e !== this.props) {
      var n = this.state.status;
      this.props.in ? n !== c && n !== h && (t = c) : (n === c || n === h) && (t = g);
    }
    this.updateStatus(!1, t);
  }, a.componentWillUnmount = function() {
    this.cancelNextCallback();
  }, a.getTimeouts = function() {
    var e = this.props.timeout, t, n, o;
    return t = n = o = e, e != null && typeof e != "number" && (t = e.exit, n = e.enter, o = e.appear !== void 0 ? e.appear : n), {
      exit: t,
      enter: n,
      appear: o
    };
  }, a.updateStatus = function(e, t) {
    if (e === void 0 && (e = !1), t !== null)
      if (this.cancelNextCallback(), t === c) {
        if (this.props.unmountOnExit || this.props.mountOnEnter) {
          var n = this.props.nodeRef ? this.props.nodeRef.current : T.findDOMNode(this);
          n && w(n);
        }
        this.performEnter(e);
      } else
        this.performExit();
    else
      this.props.unmountOnExit && this.state.status === f && this.setState({
        status: v
      });
  }, a.performEnter = function(e) {
    var t = this, n = this.props.enter, o = this.context ? this.context.isMounting : e, p = this.props.nodeRef ? [o] : [T.findDOMNode(this), o], d = p[0], m = p[1], b = this.getTimeouts(), D = o ? b.appear : b.enter;
    if (!e && !n || N.disabled) {
      this.safeSetState({
        status: h
      }, function() {
        t.props.onEntered(d);
      });
      return;
    }
    this.props.onEnter(d, m), this.safeSetState({
      status: c
    }, function() {
      t.props.onEntering(d, m), t.onTransitionEnd(D, function() {
        t.safeSetState({
          status: h
        }, function() {
          t.props.onEntered(d, m);
        });
      });
    });
  }, a.performExit = function() {
    var e = this, t = this.props.exit, n = this.getTimeouts(), o = this.props.nodeRef ? void 0 : T.findDOMNode(this);
    if (!t || N.disabled) {
      this.safeSetState({
        status: f
      }, function() {
        e.props.onExited(o);
      });
      return;
    }
    this.props.onExit(o), this.safeSetState({
      status: g
    }, function() {
      e.props.onExiting(o), e.onTransitionEnd(n.exit, function() {
        e.safeSetState({
          status: f
        }, function() {
          e.props.onExited(o);
        });
      });
    });
  }, a.cancelNextCallback = function() {
    this.nextCallback !== null && (this.nextCallback.cancel(), this.nextCallback = null);
  }, a.safeSetState = function(e, t) {
    t = this.setNextCallback(t), this.setState(e, t);
  }, a.setNextCallback = function(e) {
    var t = this, n = !0;
    return this.nextCallback = function(o) {
      n && (n = !1, t.nextCallback = null, e(o));
    }, this.nextCallback.cancel = function() {
      n = !1;
    }, this.nextCallback;
  }, a.onTransitionEnd = function(e, t) {
    this.setNextCallback(t);
    var n = this.props.nodeRef ? this.props.nodeRef.current : T.findDOMNode(this), o = e == null && !this.props.addEndListener;
    if (!n || o) {
      setTimeout(this.nextCallback, 0);
      return;
    }
    if (this.props.addEndListener) {
      var p = this.props.nodeRef ? [this.nextCallback] : [n, this.nextCallback], d = p[0], m = p[1];
      this.props.addEndListener(d, m);
    }
    e != null && setTimeout(this.nextCallback, e);
  }, a.render = function() {
    var e = this.state.status;
    if (e === v)
      return null;
    var t = this.props, n = t.children;
    t.in, t.mountOnEnter, t.unmountOnExit, t.appear, t.enter, t.exit, t.timeout, t.addEndListener, t.onEnter, t.onEntering, t.onEntered, t.onExit, t.onExiting, t.onExited, t.nodeRef;
    var o = O(t, ["children", "in", "mountOnEnter", "unmountOnExit", "appear", "enter", "exit", "timeout", "addEndListener", "onEnter", "onEntering", "onEntered", "onExit", "onExiting", "onExited", "nodeRef"]);
    return (
      // allows for nested Transitions
      /* @__PURE__ */ x.createElement(y.Provider, {
        value: null
      }, typeof n == "function" ? n(e, o) : x.cloneElement(x.Children.only(n), o))
    );
  }, s;
}(x.Component);
l.contextType = y;
l.propTypes = process.env.NODE_ENV !== "production" ? {
  /**
   * A React reference to DOM element that need to transition:
   * https://stackoverflow.com/a/51127130/4671932
   *
   *   - When `nodeRef` prop is used, `node` is not passed to callback functions
   *      (e.g. `onEnter`) because user already has direct access to the node.
   *   - When changing `key` prop of `Transition` in a `TransitionGroup` a new
   *     `nodeRef` need to be provided to `Transition` with changed `key` prop
   *     (see
   *     [test/CSSTransition-test.js](https://github.com/reactjs/react-transition-group/blob/13435f897b3ab71f6e19d724f145596f5910581c/test/CSSTransition-test.js#L362-L437)).
   */
  nodeRef: i.shape({
    current: typeof Element > "u" ? i.any : function(u, s, a, r, e, t) {
      var n = u[s];
      return i.instanceOf(n && "ownerDocument" in n ? n.ownerDocument.defaultView.Element : Element)(u, s, a, r, e, t);
    }
  }),
  /**
   * A `function` child can be used instead of a React element. This function is
   * called with the current transition status (`'entering'`, `'entered'`,
   * `'exiting'`, `'exited'`), which can be used to apply context
   * specific props to a component.
   *
   * ```jsx
   * <Transition in={this.state.in} timeout={150}>
   *   {state => (
   *     <MyComponent className={`fade fade-${state}`} />
   *   )}
   * </Transition>
   * ```
   */
  children: i.oneOfType([i.func.isRequired, i.element.isRequired]).isRequired,
  /**
   * Show the component; triggers the enter or exit states
   */
  in: i.bool,
  /**
   * By default the child component is mounted immediately along with
   * the parent `Transition` component. If you want to "lazy mount" the component on the
   * first `in={true}` you can set `mountOnEnter`. After the first enter transition the component will stay
   * mounted, even on "exited", unless you also specify `unmountOnExit`.
   */
  mountOnEnter: i.bool,
  /**
   * By default the child component stays mounted after it reaches the `'exited'` state.
   * Set `unmountOnExit` if you'd prefer to unmount the component after it finishes exiting.
   */
  unmountOnExit: i.bool,
  /**
   * By default the child component does not perform the enter transition when
   * it first mounts, regardless of the value of `in`. If you want this
   * behavior, set both `appear` and `in` to `true`.
   *
   * > **Note**: there are no special appear states like `appearing`/`appeared`, this prop
   * > only adds an additional enter transition. However, in the
   * > `<CSSTransition>` component that first enter transition does result in
   * > additional `.appear-*` classes, that way you can choose to style it
   * > differently.
   */
  appear: i.bool,
  /**
   * Enable or disable enter transitions.
   */
  enter: i.bool,
  /**
   * Enable or disable exit transitions.
   */
  exit: i.bool,
  /**
   * The duration of the transition, in milliseconds.
   * Required unless `addEndListener` is provided.
   *
   * You may specify a single timeout for all transitions:
   *
   * ```jsx
   * timeout={500}
   * ```
   *
   * or individually:
   *
   * ```jsx
   * timeout={{
   *  appear: 500,
   *  enter: 300,
   *  exit: 500,
   * }}
   * ```
   *
   * - `appear` defaults to the value of `enter`
   * - `enter` defaults to `0`
   * - `exit` defaults to `0`
   *
   * @type {number | { enter?: number, exit?: number, appear?: number }}
   */
  timeout: function(s) {
    var a = _;
    s.addEndListener || (a = a.isRequired);
    for (var r = arguments.length, e = new Array(r > 1 ? r - 1 : 0), t = 1; t < r; t++)
      e[t - 1] = arguments[t];
    return a.apply(void 0, [s].concat(e));
  },
  /**
   * Add a custom transition end trigger. Called with the transitioning
   * DOM node and a `done` callback. Allows for more fine grained transition end
   * logic. Timeouts are still used as a fallback if provided.
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * ```jsx
   * addEndListener={(node, done) => {
   *   // use the css transitionend event to mark the finish of a transition
   *   node.addEventListener('transitionend', done, false);
   * }}
   * ```
   */
  addEndListener: i.func,
  /**
   * Callback fired before the "entering" status is applied. An extra parameter
   * `isAppearing` is supplied to indicate if the enter stage is occurring on the initial mount
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * @type Function(node: HtmlElement, isAppearing: bool) -> void
   */
  onEnter: i.func,
  /**
   * Callback fired after the "entering" status is applied. An extra parameter
   * `isAppearing` is supplied to indicate if the enter stage is occurring on the initial mount
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * @type Function(node: HtmlElement, isAppearing: bool)
   */
  onEntering: i.func,
  /**
   * Callback fired after the "entered" status is applied. An extra parameter
   * `isAppearing` is supplied to indicate if the enter stage is occurring on the initial mount
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * @type Function(node: HtmlElement, isAppearing: bool) -> void
   */
  onEntered: i.func,
  /**
   * Callback fired before the "exiting" status is applied.
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * @type Function(node: HtmlElement) -> void
   */
  onExit: i.func,
  /**
   * Callback fired after the "exiting" status is applied.
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed.
   *
   * @type Function(node: HtmlElement) -> void
   */
  onExiting: i.func,
  /**
   * Callback fired after the "exited" status is applied.
   *
   * **Note**: when `nodeRef` prop is passed, `node` is not passed
   *
   * @type Function(node: HtmlElement) -> void
   */
  onExited: i.func
} : {};
function E() {
}
l.defaultProps = {
  in: !1,
  mountOnEnter: !1,
  unmountOnExit: !1,
  appear: !1,
  enter: !0,
  exit: !0,
  onEnter: E,
  onEntering: E,
  onEntered: E,
  onExit: E,
  onExiting: E,
  onExited: E
};
l.UNMOUNTED = v;
l.EXITED = f;
l.ENTERING = c;
l.ENTERED = h;
l.EXITING = g;
const $ = l, A = (u) => u.scrollTop;
function G(u, s) {
  var a, r;
  const {
    timeout: e,
    easing: t,
    style: n = {}
  } = u;
  return {
    duration: (a = n.transitionDuration) != null ? a : typeof e == "number" ? e : e[s.mode] || 0,
    easing: (r = n.transitionTimingFunction) != null ? r : typeof t == "object" ? t[s.mode] : t,
    delay: n.transitionDelay
  };
}
export {
  $ as T,
  C as c,
  U as e,
  G as g,
  A as r
};
